import React from 'react'

import PlasticHeader from "../../../static/WoodHeader.jpg"

export default function Plastic() {
    return (
        <div class="m-3">
            <h3>OTPADNO DRVO.</h3>
            <img className="plastic-img" src={PlasticHeader} />
            

            
<h5>Nudimo zbrinjavanje ambalažnog drveta:
</h5>
<ul className="plastics-list">
    <li>-Lomljene palete (svih vrsta i dimenzija)</li>
    <li>-Transportne kutije </li>
    <li>-Sanduke</li>
    


</ul>


 <p>Kontaktirajte nas - biće nam zadovoljstvo da vas posavjetujemo!</p>

        </div>
    )
}
