import React from 'react'
import './Paper.css'
import PaperHeader from '../../../static/PaperHeader.jpg'

export default function Paper() {
    return (
        <div className="m-3">
            <h3 className="mt-4">OTPADNI KARTON - PAPIR</h3>

            <img className="paper-img" src={PaperHeader} />

            <h4>Papir spada u sirovine koje su savršeno pogodne za reciklažu - postoji više proizvoda koji se proizvode od otpadnog papira.</h4>
            <p>Otpadni papir čini 70% najvažnijih materijala za proizvodnju novog papira. Svakodnevno koristimo značajne količine otpadnog papira 
                koji kao sekundarni materijal za papirnu industriju može u velikoj mjeri doprinijeti zaštiti prirodnih sirovina.</p>

            <h5>Sakupljanje otpadnog papira obavezno je za mnoga preduzeća i domaćinstva - novi proizvodi se proizvode od ove sirovine kao rezultat recikliranja.</h5>

<p>Preduzeće EKO-FORMA d.o.o. Bugojno bavi se zbrinjavanjem svih vrsta ambalažnog otpada. Sirovina koje najviše zbrinemo (oko 6000 t) je otpadni karton.

Zbrinjvanje vršimo pomoću vlastite sakupljačke mreže koja djeluje na području cijele Bosne i Hercegovine dok se naše otkupne stanice nalaze na području Bugojna i Sarajeva.

</p>
    
    <h5>Trgovina otpadnim papirom i reciklažom papira trenutno se duboko reorganizira.

</h5>

<p>Nudimo zbrinjavanje svim pravnim licima koji žele uredan i kvaltetan odvoz.

Servis vršimo pomoću kontejnera, rolo kontejnera, presa i servis manjih količina za koje nije potrebna neka specijalna oprema.

Za sve građane preporučujemo naše otkupne stanice gdje mogu predati ambalažni otpad. 


</p>
<p>Vrste otpadnog papira i kartona koji otkupljujemo:</p>
     <ul className="paper-list">
         <li>- Otpadni karton i papir (svih vrsta)</li>
         <li>- Otpadne novine</li>
        <li>- Štamparski rezanac</li>
        <li>- Termo rolne</li>
        <li>- Masni papir (reklamni)</li>
        
     </ul>
     <h5>Čitav svijet upotrebe i obrade sekundarnih materijala pod jednim krovom!</h5>
         <p>Pored ambalažnog optada nudimo uslugu šrediranja-uništenja otpadne arhivske građe za koju izdajemo <span className="importanParagraph">Izjavu o uništenju</span>. 
</p>
<p>Sirovine koje nudimo za naše kupce:</p>
<ul className="paper-list">
         <li>-Otpadni karton (baliran) 1.04., 1.05.,</li>
         <li>-Industrijski karton      4.01.,</li>
         <li>-Novine</li>
         <li>-Otpadna arhivska građa (sortirana i ne sortirana)</li>
         <li>-Otpadni rezanac (baliran)</li>
         <li>-Otpadni masni papir (reklamni)</li>
   
     </ul>
     <p>Kontaktirajte nas - biće nam zadovoljstvo da vas posavjetujemo!</p>
        </div>
    )
}
