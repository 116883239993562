import React from 'react'
import './Plastic.css'
import PlasticHeader from "../../../static/plasticHeader.jpg"

export default function Plastic() {
    return (
        <div class="m-3">
            <h3>OTPADNA PLASTIKA - UPOTREBA I OBRADA .</h3>
            <img className="plastic-img" src={PlasticHeader} />
            <h5>Eko Forma je svoje poslovanje pravilno prilagodila razvoju evropskih rješenja za preradu plastike.</h5>

            <p>EKO FORMA se etablirala kao priznati, visoko specijalizirani pružatelj usluga u okviru upotrebe plastike i njene prerade
                 u Bosni i Hercegovini. Zbrinjavanje otpadne plastike za Eko Formu je posebno zato što je potrebno i do 1000 godina za pojedine vrste plastike da se razgradi. </p>

            
<h5>Nudimo zbrinjavanje i otkup ambalažne plastike:
</h5>
<ul className="plastics-list">
    <li>-LDPE najlon – folija (transparentni I obojeni)</li>
    <li>-PE plastika  (gajbe od pića svih boja)</li>
    <li>-PET (sve vrste  platičnih boca)</li>
    <li>-PP plastika (razne kante, plastične posude sa PP bazom, crna gajbica)</li>
    <li>-HDPE  (plastične boce sa HDPE oznakom, kanisteri, burad…)</li>
    <li>-PVC (industrijski od proizvodnje prozora)</li>


</ul>
<h5>Upotreba i prerada plastike.</h5>

<p>Za naše kupce nudimo: </p>

<ul className="plastics-list">
    <li>-baliranu plastiku po vrstama</li>
    <li>-šrediranu plastiku</li>
    <li>-plastiku sortiranu po bojama i vrstama materijala</li>

</ul>

 <p>Kontaktirajte nas - biće nam zadovoljstvo da vas posavjetujemo!</p>

        </div>
    )
}
