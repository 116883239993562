import React from 'react'
import './Glass.css'
import GlassHeader from '../../../static/glassHeader.jpg'

export default function Glass() {
    return (
        <div className="m-3 ">
            <h3>Reciklaža Stakla</h3>
            <img className="plastic-img" src={GlassHeader} />
            <h5>Otpadno staklo</h5>

<p>
⦁	Nudimo otkup ambalažnog stakla svih veličina i boja (boce i tegle).
<br></br>
⦁	Zbrinjavanje ambalažnog otpada za sva pravna i fizička lica.</p>

<p>
Za naše kupce nudimo lomljeno staklo i pakovano u big-beg vreće ili lomljeno rinfuzno staklo.
</p>
            <h5>Proces reciklaže</h5>
            <p>Da bi staklena boca imala budućnost, moramo ju reciklirati. 
                Pod pojmom recikliranje podrazumijevamo organizirano sakupljanje predmeta 
                od istog materijala koji više ne možemo ili ne želimo koristiti, zatim njihovu 
                ponovnu preradu u nove proizvode i ponovnu upotrebu.</p>

               <p>Staklo se može topiti i pretvoriti u mnogo različitih oblika, od čaša za piće do staklenih vlakana.
                 Kad se staklo odnese u pogon za proizvodnju ili recikliranje, razbija se na manje komade.</p> 

                <p>Slomljeni komadi usitnjeni su, razvrstani, očišćeni i pripremljeni za miješanje s drugim sirovinama poput sode, pepela
                     i pijeska. Sirovine i komadi stakla tope se u peći, a zatim oblikuju u kalupe kako bi se stvorile nove boce različitih boja i veličina.
                      Na taj se način izrađuju nove reciklirane boce i staklenke.</p>

            <p>Kako je staklo materijal koji se može u potpunosti preraditi i to bezbroj puta, treba
                 prikupiti što veće količine starih staklenki i boca i vratiti ih u tvornicu stakla jer time:</p>
                <div className="ml-3">
                <p>- štedimo prirodne sirovine (upotrebom 1000 kg starog stakla uštedi se 700 kg pijeska, 200 kg kalcita, 200 kg sode),</p>
                <p>- štedimo energiju (trošak energije pada za 2-3 posto za svakih 10 posto udjela starog stakla u smjesi),</p>
                <p>- recikliranjem jedne boce uštedjet ćemo toliko energije koliko je potrebno žarulji od 60 W da svijetli 4 sata, računalu da radi 30 minuta, a televizor 20 minuta,</p>
                <p>- korištenjem starog stakla smanjujemo potrošnju primarnih sirovina i produljujemo životni vijek staklarske peći,</p>
                <p>- smanjujemo onečišćenje okoliša,</p>
                <p>- štedimo prostor na odlagalištima otpada.</p>

                <p>Kontaktirajte nas - biće nam zadovoljstvo da vas posavjetujemo!</p>
                </div>
        </div>
    )
}
